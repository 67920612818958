import { Layout } from '@/bloks/Global';
import { GlobalStoryblok } from '@/components';
import { ServerPageConfig, getServerPageConfig, getServerPageConfigFallback } from '@/server/config';
import { revalidate } from '@/server/revalidate';
import { getGlobalStory, getSingleStoryBySlug } from '@/server/storyblok';
import { ISbStoryData, SbParamsBase, SbParamsExtended } from '@/types/storyblok';
import { resolveRelations, version } from '@/utils/storyblok';
import { GetStaticProps, NextPage } from 'next';
import { globalFallbackStory } from '../fallback/global';
import { errorPageFallbackStory } from '../fallback/global/not-found';

interface Props {
	story: ISbStoryData;
	globalStory: ISbStoryData<GlobalStoryblok>;
	locale: string;
	preview: boolean;
	config: ServerPageConfig;
}

const Page404: NextPage<Props> = ({ ...props }) => {
	return <Layout {...props} />;
};

export const getStaticProps: GetStaticProps<Props> = async ({ locale = 'sv', locales, preview = false }) => {
	const slug = 'global/not-found';

	const sbParams: SbParamsExtended = {
		version,
		resolve_relations: resolveRelations,
		language: locale as SbParamsBase['language'],
	};

	if (preview) {
		sbParams.version = 'draft';
		sbParams.cv = Date.now();
	}

	const fallback: any = {
		story: errorPageFallbackStory,
		globalStory: globalFallbackStory,
	};

	// First initial build of 404.tsx is done during build time.
	// But there is no Storyblok token available at build time.
	// So try delay it to runtime by rendering a simplified error page with no
	// content from Storyblok and a very short revalidate time, 1 second.
	//
	// The idea is that the first 404 during runtime will trigger a build to
	// make sure that a real 404 exists with a longer revalidate time and real
	// storyblok content.
	if (!process.env.STORYBLOK_GATEWAY) {
		const { story, globalStory } = fallback;

		return {
			props: {
				story,
				globalStory,
				preview,
				locale,
				locales,
				config: getServerPageConfigFallback({ preview }),
			},
			revalidate: 1,
		};
	}

	const [story, globalStory] = await Promise.all([
		getSingleStoryBySlug(sbParams, slug).catch(() => fallback.story),

		getGlobalStory(sbParams).catch(() => fallback.globalStory),
	]);

	return {
		props: {
			story: story || false,
			globalStory: globalStory || false,
			preview,
			locale,
			locales,
			config: getServerPageConfig({ preview, slug, story }),
		},
		revalidate: revalidate.long,
	};
};

export default Page404;
